import React, { useState } from "react";
import { StoreContext } from "../context/StoreContext";
import "../styles/layout.css";
import { cn } from "../lib/helpers";
import * as styles from "./layout.module.css";

const Layout = ({ children, location }) => {
  const [activeView, setActiveView] = useState("text");
  const [activeItem, setActiveItem] = useState('');
  const [activeProject, setActiveProject] = useState('');
  const [dark, toggleDark] = useState(location?.state?.dark | false);

  return (
    <StoreContext.Provider value={{
      dark,
      activeView,
      activeItem,
      activeProject,
      setActiveProject: (newValue) => setActiveProject(newValue),
      toggleDark: (newValue) => toggleDark(newValue),
      setActiveView: (newValue) => setActiveView(newValue),
      setActiveItem: (newValue) => setActiveItem(newValue),
    }}>
      <div className={cn(styles.content, dark || location?.pathname == "/contact/" ? styles.isDark : '')}>{children}</div>
    </StoreContext.Provider>
  )
};

export default Layout;
