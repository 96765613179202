import React from "react";

const defaultState = {
  dark: false,
  activeView: 'text',
  activeItem: '',
  activeProject: '',
  setActiveProject: () => {},
  toggleDark: () => {},
  setActiveView: () => {},
  setActiveItem: () => {}
};

export const StoreContext = React.createContext(defaultState);